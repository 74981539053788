// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
  }
}

const addMobileNavFunctionality = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 1023) {
    const button = document.querySelector('.nav__button') as HTMLButtonElement;
    button.click();
  }
}

const onOpenSettings = () => {
  window?.OneTrust?.ToggleInfoDisplay();
};

const init = () => {
  if (window.Bus) {
    window.Bus.on('emu-button:click', ({ id }) => {
      if(id === 'close-contact-modal-btn') {
        document.querySelector('#hide-show-container')?.classList.remove('js-toggle-on');
      } else if(id === 'open-contact-modal-btn') {
        document.querySelector('#hide-show-container')?.classList.add('js-toggle-on');
      }
    });
  }
  document.querySelectorAll('#nav-modal a').forEach(item => {
    item.addEventListener('click', () => {
      addMobileNavFunctionality();
    });
  }) 
  document.querySelector('.cookie-settings-modal-trigger')?.addEventListener('click', () => {
    onOpenSettings();
  });
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}